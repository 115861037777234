import { buttonBaseClasses, inputLabelClasses, radioClasses } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { createTheme, Theme } from "@mui/material/styles";

const font = "'Montserrat', sans-serif";

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    info: true;
    alert: true;
    plain: true;
  }
}
declare module '@mui/material/styles' {
  interface TypographyVariants {
    logo: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    logo?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    logo: true;
  }
};


// export const basketTheme: Theme = createTheme(base, {
export const getComponents = (palette: any): Theme => {
  const base = createTheme(palette);
  const result = createTheme(base, {
    palette: {
      mode: base.palette.mode,
    },

    styleOverrides: {
      root: {
        fontFamily: "'Montserrat', sans-serif",
      }
    },
    typography: {
      allVariants: {
        fontFamily: "'Montserrat', sans-serif",
        textTransform: "none",
      },
      fontFamily: "'Montserrat', sans-serif",
      logo: {
        fontSize: '32px',
        fontWeight: 800,
      },
      h1: {
        fontSize: '26px',
        fontWeight: 700,
      },
      h2: {
        fontSize: '24px',
        fontWeight: 700,
      },
      h3: {
        fontSize: '16px',
        fontWeight: 700,
      },
      body1: {
        fontSize: '13px',
        fontWeight: 500,
      },
      body2: {
        fontSize: '13px',
      },
      subtitle1: {
        fontSize: '7vh',
      },
      subtitle2: {
        fontSize: '5vh',
      },
      button: {
        fontSize: '14px',
        fontWeight: 600,
      },
    },
    shape: {
      borderRadius: 10,
    },
    components: {

      MuiTypography: {
        //defaultProps: { fontFamily: font },
        styleOverrides: {
          root: {
            fontFamily: font,
          },
        },
      },
      MuiFormControlLabel: {
        //defaultProps: { fontFamily: font },
        styleOverrides: {
          root: {
            fontFamily: font,
            width: "100%",
            color: base.palette.text,
            '.MuiFormControlLabel-label': {
              width: '100%',
            },
            '.MuiFormControlLabel-label.Mui-disabled': {
              color: base.palette.text,
            },
          },
        },
      },
      MuiRadio: {
        //defaultProps: { fontFamily: font },
        styleOverrides: {
          root: {
            fontFamily: font,
            color: base.palette.primary.main,
            [`&.${radioClasses.checked}`]: {
              color: base.palette.primary.main,
              [`&.${radioClasses.disabled}`]: {
                color: base.palette.action.disabled,
              },
            },
            [`&.${radioClasses.disabled}`]: {
              color: base.palette.action.disabled,
            },
          },
        },
      },
      MuiCheckbox: {
        //defaultProps: { fontFamily: font },
        styleOverrides: {
          root: {
            fontFamily: font,
            color: base.palette.primary.main,
            [`&.${radioClasses.checked}`]: {
              color: base.palette.primary.main,
              [`&.${radioClasses.disabled}`]: {
                color: base.palette.action.disabled,
              },
            },
            [`&.${radioClasses.disabled}`]: {
              color: base.palette.action.disabled,
            },
          },
        },
      },
      MuiInputBase: {
        // defaultProps: { fontFamily: font },
        styleOverrides: {
          root: {
            fontFamily: font,
            color: base.palette.text.primary,
            borderColor: base.palette.text.primary,
          },
        },
      },
      MuiInputLabel: {
        //defaultProps: { fontFamily: font },
        styleOverrides: {
          root: {
            fontFamily: font,
            color: base.palette.text.disabled,
            [`&.${inputLabelClasses.error}`]: {
              color: base.palette.error,
            },
          },
        },
      },
      MuiButtonBase: {
        //defaultProps: { fontFamily: font },
      },
      MuiButton: {
        //defaultProps: { fontFamily: font },
        styleOverrides: {
          root: {
            fontFamily: font,
            width: '100%',
            height: '100%',
            fontSize: 14,
            borderRadius: 8,
            textTransform: 'none',
            minWidth: 30,
            minHeight: 30,
            [`&.${buttonBaseClasses.disabled}`]: { color: base.palette.text.secondary },
          }
        },
        variants: [
          {
            props: { color: "primary", variant: "contained" },
            style: {
              color: base.palette.background,
              backgroundColor: base.palette.primary,
              borderColor: base.palette.primary,
            }
          },
          {
            props: { color: "primary", variant: "outlined" },
            style: {
              color: base.palette.primary,
              backgroundColor: base.palette.background,
              borderColor: base.palette.primary,
              [`&.${buttonBaseClasses.disabled}`]: { color: base.palette.text.disabled },
            }
          },
          {
            props: { color: "primary", variant: "text" },
            style: {
              color: base.palette.primary,
              backgroundColor: base.palette.background,
              borderColor: base.palette.background,
              [`&.${buttonBaseClasses.disabled}`]: { color: base.palette.text.disabled },
            }
          },
          {
            props: { color: "secondary", variant: "contained" },
            style: {
              color: base.palette.background,
              backgroundColor: base.palette.secondary,
              borderColor: base.palette.secondary,
            }
          },
          {
            props: { color: "secondary", variant: "outlined" },
            style: {
              color: base.palette.secondary,
              backgroundColor: base.palette.background,
              borderColor: base.palette.secondary,
              [`&.${buttonBaseClasses.disabled}`]: { color: base.palette.text.disabled },
            }
          },
          {
            props: { color: "secondary", variant: "text" },
            style: {
              color: base.palette.secondary,
              backgroundColor: base.palette.background,
              borderColor: base.palette.background,
            }
          },
        ],
      },
      MuiBadge: {
        defaultProps: { fontFamily: font },
        styleOverrides: {
          root: {
            width: 24,
            height: 24,

            span: {
              fontSize: '10px',
              height: '15px',
              minWidth: '15px',
              borderRadius: '10px',
              padding: '5px',
              marginRight: '5px'
            },
          }
        },
      },
      MuiDialog: {
        defaultProps: { fontFamily: font },
        styleOverrides: {
          root: {
            width: '100%',
            margin: 0,
            justifySelf: 'center',
            '.MuiDialogTitle-root': {
              //padding: '8px',
              padding: '8px 4px 8px 8px',
            },
            '.MuiDialogContent-root': {
              //padding: '8px',
              padding: '8px 4px 8px 8px',
            },
            '.MuiPaper-root': {
              //maxWidth: '100vw',
              maxWidth: '100%',
              maxHeight: '100%',
              width: '100%',
              margin: 0,
            },
          }
        },
      },

      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true,
        },
      },

      MuiMenuItem: {
        defaultProps: { fontFamily: font },
        styleOverrides: {
          root: {
            fontSize: 20,
            color: base.palette.text.primary,
            backgroundColor: base.palette.background,
            '&.Mui-selected': {
              // borderStyle: 'solid',
              // borderWidth: 1,
              color: base.palette.text.secondary,
              //backgroundColor: base.palette.primary.main,
              backgroundColor: base.palette.primary,
            },
            '&.Mui-selected.Mui-focusVisible': {
              // borderStyle: 'solid',
              // borderWidth: 1,
              color: base.palette.text.secondary,
              //backgroundColor: base.palette.primary.main,
              backgroundColor: base.palette.primary,
            },
            '&:hover': {
              backgroundColor: base.palette.background,
              //BorderColor: base.palette.primary.main,
              BorderColor: base.palette.primary,
              BorderStyle: 'solid',
            },


            //.css-cawtlt-MuiButtonBase-root-MuiMenuItem-root.Mui-selected.Mui-focusVisible
            //.css-cawtlt-MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover
            //.css-cawtlt-MuiButtonBase-root-MuiMenuItem-root.Mui-selected 

            "&$selected": {
              backgroundColor: "red",
              "&:hover": {
                backgroundColor: "green"
              }
            },

            // '&:unfocus':{
            //   backgroundColor:'yellow'
            // },
            // '&:focus':{
            //   backgroundColor:'green'
            // },

          }
        }
      },

      MuiSelect: {
        defaultProps: {
          fontFamily: font,
          IconComponent: KeyboardArrowDownIcon,
          // MenuProps: {
          //   MenuListProps: {
          //     style: { padding: 0 }
          //   },
          // },
        },
        styleOverrides: {
          root: {
            // lineHeight: 0,
            // fontSize: '16px',
            // fontWeight: 600,
            // height: '100%',

            // '& .MuiInputBase-input': {
            //   alignContent: 'center',
            //   minHeight: 0,
            //   height: '100%',
            // },
            // '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
            //   paddingRight: 26,
            // },
            // "& fieldset": {
            //   borderStyle: 'none',
            //   borderWidth: 0,
            // },
            // "MuiButtonBase-root-MuiMenuItem-root": {
            //   fontSize: '16px',
            // },
            // '.MuiSvgIcon-root': {
            //   fontSize: '24px',
            // },
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: base.palette.text.primary,
            }
          }
        }
      },

    },
  });
  //console.log('getComponents', 'palette', palette, 'components', result);
  return result;
}
