//import * as React from 'react';

//import { Theme, useTheme } from "@mui/material";

export const CartIcon: React.FC<{}> = ()  => {
  // const theme:Theme = useTheme();
  // const color = theme.palette.secondary.toString();

  // stroke="currentColor"
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 25.96" stroke="currentColor" width="24" height="24" strokeWidth="1">
  <defs>
    <style>
    </style>
  </defs>
  <polygon points="29.45 25.96 2.25 25.96 0 21.45 1.79 20.55 3.49 23.96 28.29 23.96 30.27 20.5 32 21.5 29.45 25.96"/>
  <path d="M23.34,12c-.53-.75-1.24-1.27-2.11-1.56-.88-.29-1.78-.44-2.71-.44s-1.84.15-2.71.44c-.88.29-1.58.81-2.11,1.56h9.65ZM11.01,14c0-1.82.76-3.27,2.28-4.36,1.52-1.09,3.26-1.64,5.23-1.64s3.71.55,5.23,1.64c1.52,1.09,2.28,2.55,2.28,4.36h-15ZM11.01,18v-2h15v2h-15ZM7.31,22c-.42,0-.78-.14-1.1-.41s-.5-.62-.55-1.04l-1.65-16.55h5s0-4,0-4h2v4s5,0,5,0l-.25,2H6.21s1.4,14,1.4,14h1.4v2h-1.7ZM7.61,20h1.4s-1.4,0-1.4,0ZM12.01,22c-.28,0-.52-.1-.71-.29s-.29-.43-.29-.71v-1h15v1c0,.28-.1.52-.29.71s-.43.29-.71.29h-13Z"/>
</svg>
    );
}

export default CartIcon;