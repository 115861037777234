import { FC, useContext } from 'react';
import { KioskContext } from '../../../context/KioskContext';
import { KioskActionsEnum } from '../../../context/KioskContext/types';
import styles from './styles.module.scss';
import { Button, Typography } from '@mui/material';
import SalePath from '../../../models/SalePath';
import { formatMoney } from '../../../helpers/formatHelper';
import Product from '../../../models/product';
import SetCount from '../Controls/SetCount';
import IconEdit from '@mui/icons-material/EditOutlined';
import IconRemove from '@mui/icons-material/DeleteOutlineOutlined';
import Cart from '../../../models/cart';
import { updateProductRow } from '../../../helpers/basketApi';
import ProductDebug from '../Controls/ProductDebug';
import { useTranslation } from "react-i18next";
import SalePathData from '../../../models/SalePathData';

const showDebug:boolean = false;

interface Props {
  product: Product;
  disableControls?: boolean;
  onModify?: (product: Product) => void;
  onRemove?: (product: Product) => void;
}

const BasketProduct: FC<Props> = ({product, disableControls=false, onModify, onRemove}) => {

  const {t} = useTranslation();
  const {state, dispatch} = useContext(KioskContext);
  const hideControls:boolean = disableControls || (onModify===undefined && onRemove===undefined);

  const isInvalid:boolean = SalePathData.isProductInvalid(state.salePathData, product);
  const titleColor:string = isInvalid ? "error" : "text";

  const isReadonly:boolean = product.salePathCode.trim()==='';
  const salePath: SalePath|null = state.salePathData.findBySalePathCode(product.salePathCode);
  if (!salePath)return '';

  const renderChildrenLines = (item: Product, depth: number) : string[] => {
    const lines:string[] = [];
    if (item === null || item.products.length === 0) return lines;
    item.products?.forEach(i => {
      const showLine: boolean = (i.isContent && i.quantity === 0) || (!i.isContent && i.quantity !== 0);
      if (showLine){
        lines.push(`${i.quantity===0?`${t('caption.no-prefix')} `:''}${i.productName}${(i.salePrice !== 0 && !i.isContent) ? ` (+${formatMoney(i.salePrice)}€)` : ''}`);
      }
      const child:string[] = renderChildrenLines(i, depth+1);
      child?.forEach(c => lines.push(c));
    });
    return lines;
  }

  const onEditClick = () => {
    if (onModify)onModify(product);
  }
  const onRemoveClick = () => {
    if (onRemove)onRemove(product);
  }

  const onChangeQuantity = async (quantity: number) => {
      var cart: Cart | null = state.cart;
      if (product.rowId){
        if (cart!=null){
          cart = await updateProductRow(cart.id, product.rowId, quantity);
          if (cart==null){
            dispatch({ type: KioskActionsEnum.RESET_CART });
            return;
          }
        }
      }
      dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
    }

  return (
    <div className={styles.basketProduct}>
      {showDebug && <ProductDebug node={product}/>}

      <div className={styles.productImage}>
        <img src={salePath.imagePath} alt={salePath.imagePath}/>
      </div>
      <div className={styles.header}>
        <Typography className={styles.name} variant="h3" color={titleColor} sx={{textAlign: 'left'}}>{salePath.name}</Typography>
        {hideControls && <Typography className={styles.quantity} variant="h3" >x{product.quantity}</Typography>}
        <Typography className={styles.amount} variant="h3" >{formatMoney(Product.cumulatedTotalAmount(product))} €</Typography>
      </div>
      <div className={styles.content}>
        {isInvalid && (<Typography className={styles.name} variant="body1" color={titleColor} sx={{textAlign: 'left'}}>{t('caption.unable-to-order')}</Typography>)}
        {renderChildrenLines(product, 1).map((l, i) => (<div key={i}>{l}</div>))}
      </div>
      {(!hideControls && !isReadonly) && (
        <div className={styles.buttons}>
          <div className={styles.actions}>
            <div className={styles.button}>
              <Button color='primary' variant='outlined' onClick={onEditClick} className={styles.button}><IconEdit/></Button>
            </div>
            <div className={styles.button}>
              <Button color='primary' variant='outlined' onClick={onRemoveClick} className={styles.button}><IconRemove/></Button>
            </div>
          </div>
          <div className={styles.buttonCount}>
            <SetCount count={product.quantity} onChange={(count:number) => {onChangeQuantity(count)}} />
          </div>
        </div>
      )}
  </div>
  );
};

export default BasketProduct;
