import { FC, useContext } from 'react';
import { KioskContext } from '../../../context/KioskContext';
import { KioskActionsEnum } from '../../../context/KioskContext/types';
import styles from './styles.module.scss';
import { Typography } from '@mui/material';
import SalePath from '../../../models/SalePath';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Line from '../Controls/Line';

interface Props {
  salePath: SalePath;
}

const SalePathListProduct: FC<Props> = ({salePath}) => {

  const {dispatch} = useContext(KioskContext);

  const salePathClicked = () => {
    dispatch({type: KioskActionsEnum.SELECT_NODE, salePath: salePath});
  }

  const productDescription = () : string => {
    // if (salePath.infoText.length>0)return salePath.infoText;
    if (salePath.description.length>100)return `${salePath.description.substring(0,97)}...`
    return salePath.description;
  }

  return (
    <>
      <Line long/>
      <div className={styles.salePathListProduct} onClick={salePathClicked}>
      <div className={styles.productData}>
        <div className={styles.headerText}>
          <Typography variant="h3" >{salePath.name}</Typography>
        </div>
        <div className={styles.productInfo}>
        <Typography variant="body2" >{productDescription()}</Typography>
        </div>
        <div className={styles.headerAction}>

          <div className={styles.price}>
            <Typography variant="h3" >{SalePath.showPrice(salePath)} €</Typography>
          </div>
          <ChevronRightIcon className={styles.arrow}/>
        </div>
      </div>
      <div className={styles.productImage}>
        <img src={salePath.imagePath} alt={salePath.imagePath}/>
      </div>
    </div>
    </>
  );
};

export default SalePathListProduct;
