import SalePath from "./SalePath";
import ChildProductData from "./childProductData";
import Product from "./product";
import ProductData from "./productData";

export default class EditItem {

salePath: SalePath;
quantity: number = 1;
forcedPricing: boolean = false;

children: EditItem[];

product: Product | null = null;

savedState : EditItem | null = null;

constructor(
  salePath: SalePath,
  product?: Product | null
  ) {
    this.salePath = salePath;

    this.children = [];

    if (product && product.rowId?.length>0) this.product = product;

    const setChildren = (item: EditItem, salePath: SalePath): void => {
      salePath.children.forEach((salePathChild) => {
        var childProduct : Product | null = null;
        if (this.product != null && this.product.products?.length>0){
          const p = this.product.products.find((p:Product) => p.salePathCode===salePathChild.code);
          if (p)childProduct=p;
        }
        const editItemChild: EditItem = new EditItem(salePathChild, childProduct);
        item.children.push(editItemChild);
      });
    }

    setChildren(this, this.salePath);
    this.setDefaultValues();
  }

  public reset = () => {
    const resetChildren = (item: EditItem): void => {
      item.setDefaultValues();
      item.children.forEach(e => resetChildren(e));
    }
    this.setDefaultValues();
    resetChildren(this);
  }
  
  public generateProductData = () : ProductData => {

    const updateChildren = (item: EditItem, quantity:number) : Array<ChildProductData> => {
      const productDataChildren: Array<ChildProductData> = [];
      //console.log('updateChildren', item.salePath.name, item.children);//, item.children.filter((c:EditItem) => !c.salePath.isContent));
      item.children.forEach((salePathChild: EditItem) => {
        //console.log('updateChildren', 'AddToBasket', salePathChild.salePath.name, `IsContent:${salePathChild.salePath.isContent}, Qty:${salePathChild.quantity}`);
        const productDataChild: ChildProductData = new ChildProductData(
          salePathChild.salePath.productCode,
          salePathChild.quantity>0?1:0,
          '',
          //price,
          0,
          salePathChild.salePath.conseptName,
          salePathChild.salePath.code,
          salePathChild.salePath.isContent,
          []);
        if (salePathChild.salePath.isSelectOne()) productDataChild.quantity = 0;
        productDataChild.childProducts = updateChildren(salePathChild, quantity);
        productDataChildren.push(productDataChild);
      });
      //console.log('updateChildren', 'result', item.salePath.name, productDataChildren);
      return productDataChildren;
    }

    const result: ProductData = new ProductData(
      this.salePath.productCode,
      this.quantity,
      "",
      null,
      this.salePath.conseptName,
      this.salePath.code,
      this.salePath.isContent,
      []);
      result.childProducts= updateChildren(this, result.quantity);
    ;
    return result;
  }

  public findProductBySalePathCode = (product: Product, salePathCode: string) : Product | null => {
      if (product.salePathCode === salePathCode){
        return product;
      }
      for (let i = 0; i < product.products.length; i++) {
        const result = this.findProductBySalePathCode(product.products[i], salePathCode)
        if (result){
          return result;
        }
      }
      return null;
  }

  private static countQuantity = (root:EditItem) : number => {
    var total:number = root.quantity;
    root.children.forEach(c => total += this.countQuantity(c));
    return total;
  }

  private setDefaultValues = () => {
    if (this.product){
      if (this.salePath.chainFreeUnits === 1){
        this.quantity = EditItem.countQuantity(this)>0?1:0;
      } else {
        this.quantity = this.product.quantity;
      }
      this.forcedPricing = this.product.salePrice !== 0;
    } else {
      if (this.salePath.isOptional){
        if (this.salePath.parent?.chainFreeUnits === 1){
          this.quantity = 0;
        } else {
          if (this.salePath.chainIsFreeOfCharge){
            this.quantity = 1;
          } else {
            this.quantity = 0;
          }
        }
      }
      else {
        this.quantity = 1;
      }
    }
  }

  public getForcedPrices = () : number => {
    var amount:number = this.forcedPricing===true?this.salePath.salePrice : 0;
    this.children.forEach(i => {
      amount += i.getForcedPrices();
    });
    //console.log('getForcedPrices', amount, this.salePath.name, this.children);
    return amount;
  }

  public static sort  =(array:EditItem[]) : EditItem[] => {
    return array.sort((n1: EditItem, n2: EditItem) => n1.salePath.orderNumber - n2.salePath.orderNumber);
  }

}
