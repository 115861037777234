import { FC, useContext, useEffect, useState } from 'react';
import { KioskContext } from '../../../../context/KioskContext';
import styles from './styles.module.scss';
import { Button, Typography } from '@mui/material';
import { getReceipt } from '../../../../helpers/basketApi';
import { KioskActionsEnum } from '../../../../context/KioskContext/types';
import { useTranslation } from "react-i18next";

interface Props {
  cartId: string
}

const Receipt: FC<Props> = ({cartId}) => {

  const {dispatch} = useContext(KioskContext);
  const {t} = useTranslation();
  const [imageUrl, setImageUrl] = useState<string>('');

  useEffect(()=>{
    const downloadImage = async () => {
      const data = await getReceipt(cartId, "png");
      if (data){
        const url = URL.createObjectURL( data );
        setImageUrl(url);
      }
    };
    if (imageUrl===''){
      downloadImage();
    }
  });

  const close = () => {
    dispatch({ type: KioskActionsEnum.SHOW_RECEIPT, cartId: null});
  }

  const heightHeader:number = 55;
  const heightFooter:number = 50;
  const heightContainer:number = window.innerHeight - heightHeader;
  const heightImage:number = window.innerHeight - heightHeader - heightFooter;

  return (
  <div className={styles.receipt} style={{height:`${heightContainer}px`}}>

    <div className={styles.image} id='container' style={{maxHeight:`${heightImage}px`}}>
      { imageUrl!=='' && <img src={imageUrl} alt='' />}
    </div>

    <div className={styles.footer} id='footer'>
      <Button color='primary' variant='contained' onClick={close} className={styles.button}>
        <div className={styles.buttonText}>
          <Typography variant="h3">{t('button.close')}</Typography>
        </div>
      </Button>
    </div>

  </div>
  );
};

export default Receipt;
