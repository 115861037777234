import { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import EditItem from '../../../../../models/EditItem';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { formatMoney } from '../../../../../helpers/formatHelper';

interface Props {
  title?: string;
  editItem: EditItem;
  disabled?: boolean;
  showPrice?: boolean;
  onChange?: (item: EditItem, checked: boolean) => void;

}

const ProductSelectManyItem: FC<Props> = ({title = undefined, editItem, disabled=false, showPrice=false, onChange}) => {

  const [ refreshing, refresh ] = useState<boolean>(false);
  useEffect(()=>{if (refreshing)refresh(false)},[refreshing]);
    
  const toggleChecked = () => {
    const newQuantity:number = editItem.quantity !== 0 ? 0 : 1;
    editItem.quantity = newQuantity;
    editItem.forcedPricing = newQuantity !== 0 && editItem.salePath.salePrice !== 0;
    refresh(true);
    if (onChange){
      onChange(editItem, editItem.quantity !== 0);
    }
  }

  //console.log('ProductSelectManyItem', title || editItem.salePath.name, showPrice, formatMoney(editItem.salePath.salePrice));

  return (
    <FormControlLabel className={styles.item} 
      control={
      <Checkbox disabled={disabled} checked={editItem.quantity!==0} onChange={toggleChecked} />}
      label={
      <div className={styles.label}>
        <Typography variant='body2'>{title || editItem.salePath.name}</Typography>
        { showPrice && <Typography variant="body2">{formatMoney(editItem.salePath.salePrice)} €</Typography>}
      </div>}
      labelPlacement="end"
    />
    );
};

export default ProductSelectManyItem;
