import { FC, ReactNode } from 'react';
import styles from './styles.module.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export interface Props {
  icon?: ReactNode,
  title?: string,
  content?: string,
  children?: React.ReactNode,
  cancelText?: string,
  confirmText?: string,
  defaultResult?: boolean,
  closingResult?: boolean,
  visible: boolean;
  width?: number|null;
  onClose?: (result: boolean) => void;
}

const ConfirmDialog: FC<Props> = ({icon, title = '', content = '', children, cancelText='', confirmText='', defaultResult=false, closingResult=false, visible, width=null, onClose}) => {
  const {t} = useTranslation();
  const header: string = title.length>0 ? title : t('caption.confirm');
  const labelConfirm: string = confirmText.length>0 ? confirmText : t('button.yes');
  const labelCancel: string = cancelText.length>0 ? cancelText : t('button.no');

  const handleClose = (useResult:boolean): void => {
    if (onClose) onClose(useResult);
  };

  return (
    <Dialog onClose={() => {handleClose(closingResult)}} open={visible} className={styles.dialog} TransitionComponent={Transition} style={{ width: `${width?`${width}px`:'100%'}`}}>
      <DialogTitle className={styles.title}>
        <Typography variant="h1">{header}</Typography>
        {icon && (icon)}
      </DialogTitle>
      <DialogContent sx={{scrollbarWidth: 'thin', padding: '0'}}>
        <Typography variant='body1'>{content}</Typography>
        {children}
      </DialogContent>
      <DialogActions className={styles.actions}>
        <div className={styles.buttons}>
          <Button color='primary' variant='outlined' onClick={() => {handleClose(false)}}>{labelCancel}</Button>
          <Button color='primary' variant='contained' onClick={() => {handleClose(true)}}>{labelConfirm}</Button>
        </div>
      </DialogActions>
   </Dialog>
  );
};

export default ConfirmDialog;
