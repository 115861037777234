import { FC, useContext, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import { FormControlLabel, MenuItem, Radio, RadioGroup, Select, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { KioskContext } from '../../../../context/KioskContext';
import SalePath from '../../../../models/SalePath';
import SalePathData from '../../../../models/SalePathData';
import SalePathOpeningHours from '../../../../models/SalePathOpeningHours';
import { getCartState, setCartState } from '../../../../helpers/localStorageHelper';

interface Props {
  onValidate?: (result: boolean) => void;
}

const DeliveryTime: FC<Props> = ({onValidate}) => {
  const {state} = useContext(KioskContext);

  // const timeStep:number = 60;
  // const timeGap:number = 10;
  // const timeMaxHours:number = 20;
  const timeStep:number = 10;
  const timeGap:number = 10;
  const timeMaxHours:number = 3;

  const timeMinValue:Dayjs = dayjs(dayjs().format('YYYY-MM-DD HH:mm'),'YYYY-MM-DD HH:mm').add(timeGap, 'minutes');
  const timeMaxValue:Dayjs = dayjs().add(timeMaxHours,'hours');


  const defaultMethod = () : string => {
    const value = getCartState().pickupTime;
    if (value && value.length>0)
      return 'scheduled';
    return 'now';
  }
  const defaultTime = () : Dayjs => {
    const value = getCartState().pickupTime;
    if (value && value.length>0)
      return dayjs(value);
    return timeMinValue;
  }

  const {t} = useTranslation();
  const [concepts] = useState<SalePath[]>(state.cart ? SalePathData.getConcepts(state.salePathData, state.cart) : []);
  const [selectedMethod, setSelectedMethod] = useState<string>( defaultMethod() );
  const [selectedTime, setSelectedTime] = useState<Dayjs>( defaultTime() );

  const timeFormatScreen:string = 'HH.mm';
  const timeFormatScreenTomorrow:string = 'HH.mm (DD.MM.)';
  const timeFormatValue:string = 'YYYYMMDDHHmm';
  const formatTime = (value: Dayjs|null|undefined) : string => {
    if (!value)return '-';
    if (value.format('YYYYMMDD') === dayjs().format('YYYYMMDD'))return value.format(timeFormatScreen);
    return value.format(timeFormatScreenTomorrow);
  }
  const formatTimeValue = (value: Dayjs|null|undefined) : string|null => {
    return value ? value.format(timeFormatValue) : null;
  }
  // const convertTime = (value: string|null|undefined) : Dayjs|null => {
  //   if (!value)return null;
  //   return dayjs(value,timeFormatScreen);
  // }
  // const convertTimeValue = (value: Dayjs|null|undefined) : Dayjs|null => {
  //   if (!value)return null;
  //   return dayjs(value,timeFormatValue);
  // }


  const isValidTime = (value: Dayjs) : boolean => {
    const formatStr = timeFormatValue;
    //console.log('isValidTime', value.format(formatStr), `${timeMinValue.format(formatStr)}-${timeMaxValue.format(formatStr)}`);
    if (value > timeMaxValue)return false;
    const now:Date = value.toDate();
    let result:boolean = true;
    concepts.forEach(c => {
      if (c.openingHours !== null && SalePathOpeningHours.getOpeningHours(c.openingHours,now).allowPickup()===false){
        result = false;
      }
    });
    return result;
  }

  const buildChoices = (fromTime:Dayjs = dayjs()) : Dayjs[] => {
    const choices:Dayjs[] = [];
    let time:Dayjs = dayjs(dayjs().format('YYYY-MM-DD HH:00'),'YYYY-MM-DD HH:mm');
    while(time <= timeMaxValue){
      if (time >= timeMinValue){
        if (isValidTime(time)===true){
          choices.push(time);
        }
      }
      time = time.add(timeStep, 'minutes');
    }
    return choices;
  }

  const timeChoices:Dayjs[] = buildChoices();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue:string = event.target.value;
    setSelectedMethod(newValue);
    const time: string|null = newValue === 'now' ? null : formatTimeValue(timeChoices[0]);

    const cartState = getCartState();
    cartState.pickupTime = time||'';
    setCartState(cartState);
    validate(newValue, selectedTime);
  };

  if (!isValidTime(selectedTime)){
    setSelectedTime(timeChoices[0]);
    const cartState = getCartState();
    cartState.pickupTime = formatTimeValue(timeChoices[0])||'';
    setCartState(cartState);
    //console.log('INVALID TIME', selectedTime);
  }

  const validate = (selected:string, pickupTime:Dayjs|null) => {
    const isValid = selected ==='now' || (pickupTime !== null && isValidTime(pickupTime));
    if (onValidate)onValidate(isValid);
  };

  const showTime:boolean = (getCartState().pickupTime||'').length>0;

  const selectChoices = (): any => {
    return timeChoices.map((t: Dayjs, i:number) => {
      return (<MenuItem key={i} value={t.format(timeFormatValue)}>{formatTime(t)}</MenuItem>);
    });
  };

  const changeTime = (e: any): void => {
    const time = e.target.value;
    const value = dayjs(time, timeFormatValue)
    const cartState = getCartState();
    cartState.pickupTime = formatTimeValue(value)||'';
    setCartState(cartState);
    setSelectedTime(value);
    //console.log('changeTime', time, cartState.pickupTime, value);
  };

  const selectedValue = (): string => {
    if (timeChoices.length===0)return '';
    //const cartState = getCartState();
    const choice = timeChoices.find(t => formatTimeValue(t)===formatTimeValue(selectedTime));
    const result = choice ? formatTimeValue(choice)||'' : formatTimeValue(timeChoices[0])||'';
    //console.log('selectedValue', choice?'Found':'NOT found', result, formatTimeValue(selectedTime), choice&&formatTimeValue(choice), timeChoices);

    // const cartState = getCartState();
    // const choice = timeChoices.find(t => t.format('HH:mm')===cartState.pickupTime||'');
    // const result = choice ? choice.format('HH:mm') : timeChoices[0].format('HH:mm');
    // console.log('selectedValue', result, cartState.pickupTime, formatTime(selectedTime), choice, timeChoices);
    return result;
  };

  return (
    <div className={styles.deliveryTime}>
      <Typography variant="h3" >{t(`caption.delivery-time`)}</Typography>
      <RadioGroup
        value={selectedMethod}
        onChange={handleChange}
      >
        <FormControlLabel value='now' control={<Radio />} label={t(`caption.delivery-time-now`)} className={styles.choice} />
        <FormControlLabel value='scheduled' disabled={timeChoices.length===0} control={<Radio />} label={`${t(`caption.delivery-time-scheduled`)}`} className={styles.choice} />
        {showTime && (
          <Select
            className={styles.deliveryTimeSelect}
            value={selectedValue()}
            onChange={changeTime}
            MenuProps={{
              MenuListProps: {
                style: {
                  maxHeight: '50vh',
                }
              }
            }}
            sx={{
              fontSize: '16px',
          }}>
            {selectChoices()}
          </Select>
        )}
      </RadioGroup>
    </div>
  );
};

export default DeliveryTime;