import { FC, useContext, useEffect, useRef, useState } from 'react';
import { KioskContext } from '../../../../context/KioskContext';
import styles from './styles.module.scss';
import { Button, List, Typography } from '@mui/material';
import { OrderStateEnum, getCart } from '../../../../helpers/basketApi';
import { KioskActionsEnum } from '../../../../context/KioskContext/types';
import { useTranslation } from "react-i18next";
import Line from '../../Controls/Line';
import Shop from '../../Shop';
import Cart from '../../../../models/cart';
import OrderStatusEntry from '../../Controls/OrderStatusEntry';
import useRefDimensions from '../../../../hooks/useRefDimensions';

interface Props {
  cartId: string
}

const ReceiptStatus: FC<Props> = ({cartId}) => {

  const [isPolling, setisPolling] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);
  const interval:number = 15 * 1000; // 15 secs
  const maxCount:number = Math.floor((5 * 60000) / interval); // 5 min

  const refThis = useRef<HTMLDivElement|null>(null);
  const refList = useRef<HTMLDivElement|null>(null);
  const { height:titleHeight } = useRefDimensions(refThis);

  const lastItemRef = useRef<HTMLDivElement|null>(null);

  const [cart, setCart] = useState<Cart|null>(null);

  const {dispatch} = useContext(KioskContext);
  const {t} = useTranslation();

  const getStep = (cartData:Cart|null=cart) : number => {
    if (cartData==null)return 0;
    switch (cartData.orderState){
      case OrderStateEnum.Waiting: return 1;
      case OrderStateEnum.Preparing: return 2;
      case OrderStateEnum.Collecting: return 3;
      case OrderStateEnum.Ready: return 4;
      case OrderStateEnum.Delivered: return 5;
      default: return 0;
    }
  }

  useEffect(() => {
    setCount(0);
  },[cartId]);

  useEffect(() => {
    const checkCartState = async () => {
      var data: Cart | null = await getCart(cartId);
      setCart(data);
      if (data && data.orderState === OrderStateEnum.Delivered){
        setisPolling(false);
      }
    }
    const tick = () => {
      setCount((c) => c+1);
      if (count>=maxCount){
        setisPolling(false);
        dispatch({ type: KioskActionsEnum.SHOW_RECEIPT, cartId: null});
        return;
      }
      checkCartState();
    }
  
    let timeoutId:NodeJS.Timeout|null = null;
    if (count===0)tick();
    if (isPolling){
      timeoutId = setTimeout(() => tick(),interval);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  },[cartId, isPolling, count, dispatch, maxCount, interval]);

  const close = () => {
    dispatch({ type: KioskActionsEnum.SHOW_RECEIPT, cartId: null});
  }

  const scrollToTop = () => {
    if (refList && refList.current) {
      refList.current.scrollTo(0,0);
    }
  }

  const heightHeader:number = 55;
  const heightFooter:number = 65;
  const heightContainer:number = window.innerHeight - heightHeader;

  const lastItemIndex = getStep();

  const heightList:number = heightContainer - titleHeight - heightFooter;

  scrollToTop();

  return (
  <div className={styles.receiptStatus} style={{height:`${heightContainer}px`}} onClick={scrollToTop}>

    <div ref={refThis} className={styles.header}>
      <div className={styles.titleItem}>
        <Typography variant="h1">{t('caption.order')} {cart?.serviceID}</Typography>
      </div>
      <div className={styles.titleItem}><Shop allowToChange={false} /></div>
      <Line short />
    </div>
    <div ref={refList} className={styles.list} style={{height:`${heightList}px`}}>
      <List>
        {[5,4,3,2,1].map(i => (
        <div key={i} ref={lastItemIndex===i?lastItemRef:null} id={`state${i}`} >
          <OrderStatusEntry visible={lastItemIndex >= i} disabled={lastItemIndex!==i} index={i} header={t(`caption.orderState-${i}-primary`)} information={t(`caption.orderState-${i}-secondary`)}/>
        </div>
        ))}
      </List>
    </div>

    <div className={styles.footer} id='footer'>
      <Button color='primary' variant='contained' onClick={close} className={styles.button}>
        <div className={styles.buttonText}>
          <Typography variant="h3">{t('button.close')}</Typography>
        </div>
      </Button>
    </div>

  </div>
  );
};

export default ReceiptStatus;
