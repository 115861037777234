import { FC } from 'react';
import styles from './styles.module.scss';
import { Button, Typography } from '@mui/material';

interface Props {
  count: number;
  minCount?: number;
  maxCount?: number;
  enabled?: boolean;
  onChange?: (count: number) => void;
}

const SetCount: FC<Props> = ({count, minCount=1, maxCount=9, enabled=true, onChange}) => {

  const setCount = (count: number) => {
    if ((count>=minCount && count <= maxCount) && onChange) {
      onChange(count);
    }
  }

  const canDecrease = () : boolean => {
    return enabled && count>minCount;
  }
  const decreaseCount = () => {
    if (canDecrease()) {
      setCount(count-1);
    }
  }
  const canIncrease = () : boolean => {
    return enabled && count<maxCount;
  }
  const increaseCount = () => {
    if (canIncrease()) {
      setCount(count+1);
    }
  }

  return (
    <div className={styles.countButtons}>
      <Button color='primary' variant='outlined' onClick={decreaseCount} disabled={!canDecrease()} className={styles.countButton}><h1>-</h1></Button>
      <Typography color='primary' variant="body1" className={styles.countText}>{count}</Typography>
      <Button color='primary' variant='outlined' onClick={increaseCount} disabled={!canIncrease()} className={styles.countButton}><h1>+</h1></Button>
    </div>
  );
};

export default SetCount;
