import { ErrorInfo, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { KioskContext } from '../../../context/KioskContext';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import ConceptList from '../ConceptList';
import SalePathListItems from '../SalePathListItems';
import SalePathBreadcrumbs from '../SalePathBreadcrumbs';
import SalePathProduct from '../SalePathProduct';
import BasketDialog from '../Dialogs/BasketDialog';
import Header from '../Header';
import Welcome from '../Welcome';
import Shop from '../Shop';
import { StepsEnum } from '../../../context/KioskContext/types';
import Line from '../Controls/Line';
import Order from '../Steps/Order';
import Paid from '../Steps/Paid';
import Receipt from '../Steps/Receipt';
import ReceiptStatus from '../Steps/ReceiptStatus';
import OpenCart from '../Controls/OpenOrder';
import useWindowState from '../../../hooks/useWindowState';
import { handleApplicationError } from '../../../helpers/loggerApi';
import ErrorBoundary from '../ErrorBoundary';
import Footer from '../Footer';

interface Props {
  shopCode: string;
  tableNumber: string|null;
  cartId: string|null;
}

const Main: FC<Props> = ({shopCode, tableNumber, cartId}) => {
  const {state, dispatch} = useContext(KioskContext);
  const { portrait, width: windowWidth, height: windowHeight } = useWindowState();

  const [ refreshing, refresh ] = useState<boolean>(false);
  const {i18n} = useTranslation();

  const htmlStateKey:string = 'data-state';
  const htmlState:string = document.documentElement.getAttribute(htmlStateKey)||'init';

  useEffect((): void => {
    var htmlStateUpdate:string = state.step ? state.step.toString() : '';
    if (htmlState !== htmlStateUpdate){
      document.documentElement.setAttribute(htmlStateKey, htmlStateUpdate);
    }
  }, [htmlState, state.step])

  useEffect(()=>{if (refreshing)refresh(false)},[refreshing]);

  useEffect(()=>{refresh(true)},[i18n.language]);
  useEffect(()=>{
    refresh(true);
  },[state.salePath]);
  useEffect(()=>{
    if (cartId){
      //dispatch({ type: KioskActionsEnum.OBSERVE_CART, cartId: cartId });
    }
  },[dispatch, cartId]);

  const showBasketButton: boolean = htmlState.toLowerCase()==='collect';

  useEffect(()=>{
    refresh(true);
  },[portrait, windowHeight]);

  useEffect(()=>{
    window.dispatchEvent(new Event('resize'));
  },[showBasketButton]);

  const onError = async (area: string, error:Error, info:React.ErrorInfo) => {
    handleApplicationError(area, state.cart?.id, undefined, error);
  }

  const showCartButton:boolean = (state.cart&&(state.cart.products.length > 0 && state.cart.state.toLowerCase() !== 'finalized'))||false;
  const showFrontFooter:boolean = state.salePath == null && state.step !== StepsEnum.order;

  const heightHeader:number = 60;
  const heightFooter:number = 0;
  const heightFrontFooter:number = 45;

  const showFooter:boolean = false;
  const heightBreadcrumb:number = 20 + 16 + 16;
  const heightContainer:number = window.innerHeight - heightHeader - (showFooter ? heightFooter: 0) - (showFrontFooter ? heightFrontFooter : 0);
  const heightBrowsing:number = heightContainer - heightBreadcrumb;

  const maxWidth:number|null = 600;
  const width:number|null = windowWidth ? windowWidth < maxWidth ? windowWidth : maxWidth : null;
  const marginH:number|null = windowWidth ? (windowWidth-(width||0))/2 : null;
  const cartButtonBottom:number = 8 + (showFrontFooter ? heightFrontFooter : 0);

  const content = () : ReactNode => {
    if (state.showReceiptStatusCartId){
      return <ReceiptStatus cartId={state.showReceiptStatusCartId}/>
    }
    if (state.showReceiptCartId){
      return <Receipt cartId={state.showReceiptCartId}/>
    }
    if (state.observedCartId){
      return <Paid cartId={state.observedCartId}/>
    }

    switch(state.step){
      case StepsEnum.order: {
        return <Order />;
      }
      // case StepsEnum.payment: {
      //   return <Pay/>
      // }
    }          
    return (state.salePath == null) ? (
      <div className={styles.front}>
        <div className={styles.conceptsHeader}>
          <div className={styles.headerItem}><Welcome/></div>
          <div className={styles.headerItem}><Shop/></div>
          <div className={styles.headerItem}><Line short/></div>
        </div>
        <div className={styles.conceptsContainer}>
          <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('Concepts', error, info)}>
            <ConceptList/>
            <div style={{height:60}}/>
            </ErrorBoundary>
        </div>
        {showFrontFooter && (
          <div style={{position: 'absolute', width:`${width&&`${width}px`}`, left:`${marginH&&`${marginH}px`}`, bottom: '0px'}}>
            <Footer width={width}/>
          </div>
          )}
        </div>
    ) : (
      <div className={styles.browse}>
        <div className={styles.breadcrumbs}>
          {(state.salePath && !state.isConfirmingBasket) && <SalePathBreadcrumbs/>}
        </div>
        <div style={{height:`${heightBrowsing}px`}}>
          {
            (state.salePath?.nodeType.toLowerCase() === 'product') ? 
            <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('SalePathItem', error, info)}>
              <SalePathProduct />
              </ErrorBoundary>
            :
            <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('SalePathList', error, info)}>
              <SalePathListItems />
              </ErrorBoundary>
          }
        </div>
      </div>
    );
  }
  
return (
  <div className={styles.root} style={{maxWidth:`${width&&`${width}px`}`}}>
    <div className={styles.header} id='header'>
      <Header/>
    </div>
    <div className={styles.mainContainer} id='container' style={{height:`${heightContainer}px`}}>
      {content()}
    </div>
    {showCartButton && (
      <div className={styles.cartButton} style={{position: 'absolute', width:`${width&&`${width}px`}`, left:`${marginH&&`${marginH}px`}`, bottom: cartButtonBottom}}>
        <OpenCart />
      </div>
    )}
    <BasketDialog visible={state.step === StepsEnum.cart} width={width} />
  </div>      
  );
};

export default Main;

