import { FC } from 'react';
import styles from './styles.module.scss';
import { Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SalePath from '../../../../models/SalePath';
import { useTranslation } from 'react-i18next';

interface Props {
  primary: string;
  secondary?: string;
  salePath?: SalePath;
  onClick?: () => void;
}

const ListItem: FC<Props> = ({primary, secondary=null, salePath=null, onClick}) => {

  const {t} = useTranslation();

  const isRoot: boolean = salePath ? salePath.isRoot() : false;
  const isEnabled: boolean = isRoot ? salePath?.openingHours?.isOpen() ?? true : true;
  const openingHours: string = !isEnabled ? salePath?.openingHours?.getOpeningHours().today?.isClosedAllDay() ? t('caption.closed-today'): `${t('caption.open-today')}: ${salePath?.openingHours?.getOpeningHours().today?.toUIString()??''}` : '';

  const clicked = () => {
    if (!isEnabled)return;
    if (onClick)onClick();
  }

  return (
    <div className={styles.listItem} onClick={clicked}>
      <div className={styles.info}>
      <Typography variant="h3" >{primary}</Typography>
      {openingHours.length>0&&<Typography variant="body1" >{openingHours}</Typography>}
      {secondary&&<Typography variant="body1" >{secondary}</Typography>}
      </div>
      {isEnabled && (
      <div className={styles.action}>
        <ChevronRightIcon className={styles.arrow}/>
      </div>
      )}
    </div>
  );
};

export default ListItem;
