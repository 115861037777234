import { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import EditItem from '../../../../../models/EditItem';
import { useTranslation } from "react-i18next";
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import SalePathDebug from '../../../Controls/SalePathDebug';
import EditItemDebug from '../../../Controls/EditItemDebug';

const showEditItemDebug:boolean = false;
const showSalePathDebug:boolean = false;

interface Props {
  title: string;
  editItem: EditItem;
  choices: EditItem[];
  onChange?: () => void;
}

const ProductSelectOne: FC<Props> = ({title, editItem, choices, onChange}) => {

  const getCode = (item: EditItem) : string => {return item.salePath.code;}

  const [selectedCode, setSelectedCode] = useState<string>()

  const {t} = useTranslation();

  useEffect(() => {
    const choice:EditItem|undefined = choices.find(i => i.quantity !== 0);
    var code:string = '';
    if (choice) {
      code = choice.salePath.code;
    } else {
      if (!editItem.salePath.isOptional && choices.length>0){
        code = choices[0].salePath.code;
      }
    }
    setSelectedCode(code);
    choices.forEach(e => {
      e.quantity = getCode(e) === code ? 1 : 0;
    });
  },[choices, editItem.salePath.isOptional]);

  const setQuantities = (selected: string) => {
    choices.forEach(e => {
      e.quantity = getCode(e) === selected ? 1 : 0;
    });
  }

  const handleSelectOne = (event: React.ChangeEvent<HTMLInputElement>) => {
    const code:string = event.target.value; 
    setSelectedCode(code);
    setQuantities(code);
    if (onChange)onChange();
  };

  return (
    <div className={styles.productSelect}>
      {showEditItemDebug && (
      <div style={{marginTop:5}}>
        <EditItemDebug node={editItem} showDepth={1} />
      </div>
      )}
      {showSalePathDebug && (
      <div style={{marginTop:5}}>
        <SalePathDebug node={editItem} showDepth={1} />
      </div>
      )}

      <Typography variant="h3">{title}</Typography>
      <RadioGroup>
        {editItem.salePath.isOptional&&(
          <FormControlLabel key={-1} control={<Radio value={''} checked={selectedCode===''} onChange={handleSelectOne} />}
          label={t('caption.not-selected')}
          labelPlacement="end"
      />          
      )
        }
        {choices.map((choice:EditItem, i:number)=>(
          <FormControlLabel key={i} control={<Radio value={choice.salePath.code} checked={selectedCode===choice.salePath.code} onChange={handleSelectOne} />}
            label={choice.salePath.name}
            labelPlacement="end"
        />          
        ))}
      </RadioGroup>
    </div>);
};
    
export default ProductSelectOne;
