import { FC, useContext } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Cart from '../../../models/cart';
import { KioskContext } from '../../../context/KioskContext';
import useQueryParams from '../../../hooks/useQueryParams';

interface Props {
  allowToChange?: boolean;
  cart?: Cart;
}

const Shop: FC<Props> = ({allowToChange=true, cart=undefined}) => {

  const {state} = useContext(KioskContext);
  const navigate = useNavigate();
  const {t} = useTranslation();
  const queryParams = useQueryParams();
  const fixedShop: boolean = queryParams.shopCode != null && queryParams.tableNumber != null;

  const source: Cart|null = cart || state.cart;

  const selectionUrl: string = source?.posParameters?.find(p => p.parameterCode.toLowerCase()==='basketshopurl')?.parameterValue||'';

  const selectShop = () => {
    if (selectionUrl.toLowerCase().startsWith('http')){
      window.location.href = selectionUrl;
    } else {
      navigate(selectionUrl);
    }
    //navigate(selectionUrl);
    // const url:string = process.env.REACT_APP_SHOPS_URI||'/shop';
    // navigate(url);
  }

  const shopName: string = source?.shop?.shopName || '';
  const address: string | null = source?.shop ? `${source?.shop.streetAddress}, ${source?.shop.city}` : null;
  const phoneNumber: string | null = (source?.shop && source?.shop.phone?.length>0) ? `${t('caption.phone')}: ${source?.shop.phone}` : null;
  
  return (
    <div className={styles.shop}>
      <div className={styles.shopInfo}>
        <Typography variant="h3" >{t('caption.shop')}</Typography>
        <Typography variant="body1">{shopName}</Typography>
        {address && <Typography variant="body2">{address}</Typography>}
        {phoneNumber && <Typography variant="body2">{phoneNumber}</Typography>}
      </div>
      {(allowToChange && !fixedShop && selectionUrl !== '') && (
        <div className={styles.shopAction}>
          <Button color='primary' variant='contained' onClick={selectShop}>{t('button.change')}</Button>
        </div>
      )}
    </div>
  );
};

export default Shop;
