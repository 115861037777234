import { FC } from 'react';
import styles from './styles.module.scss';
import EditItem from '../../../../models/EditItem';
import ProductSelectMany from './ProductSelectMany';
import ProductSelectOne from './ProductSelectOne';

interface Props {
  title?: string;
  editItem: EditItem;
  relatedChildren?: EditItem[];
  onChange?: () => void;
}

const ProductOption: FC<Props> = ({title = undefined, editItem, relatedChildren=[], onChange}) => {

  const selectOne:boolean = editItem.salePath.isSelectOne();

  const changed = () => {
    if (onChange)onChange();
  };

  return (

    <div  className={styles.productOption}>
      {selectOne ? (
        <ProductSelectOne title={title || editItem.salePath.name} editItem={editItem} choices={relatedChildren} onChange={changed}/>
      ) : (
        <ProductSelectMany title={title || editItem.salePath.name} editItem={editItem} choices={relatedChildren} onChange={changed}/>
      )}
    </div>
    );
};

export default ProductOption;
