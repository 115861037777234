import { FC } from 'react';
import styles from './styles.module.scss';
import useTheme from '@mui/material/styles/useTheme';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';


interface Props {
  index: number;
  header: string;
  information?: string;
  visible?: boolean;
  disabled?: boolean;
}

const OrderStatusEntry: FC<Props> = ({index, header, information='', visible=true, disabled=false}) => {

  const theme = useTheme();

  if (!visible)return <></>;

  return (
    <ListItem key={index}>
      <ListItemIcon >
        <div className={`container ${styles.waitingNumberContainer}`} style={{backgroundColor: `${ disabled ? theme.palette.action.disabledBackground : theme.palette.primary.main}`}}>
          <Typography variant='h3' color='text.secondary'>{index}</Typography>
        </div>
      </ListItemIcon>
      <ListItemText primary={header} secondary={information}
      primaryTypographyProps={{style:{color:`${theme.palette.text.primary}`}}}
      secondaryTypographyProps={{style:{color:`${theme.palette.text.primary}`}}} />
    </ListItem>
  );
};

export default OrderStatusEntry;
