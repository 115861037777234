import { FC } from 'react';
import styles from './styles.module.scss';
import { Button, Typography } from '@mui/material';
import { formatMoney } from '../../../../helpers/formatHelper';
import { useTranslation } from "react-i18next";
import EditItem from '../../../../models/EditItem';
import SalePath from '../../../../models/SalePath';

interface Props {
  editItem: EditItem;
  enabled?: boolean;
  onClick?: () => void;
}

const AddToCart: FC<Props> = ({editItem, enabled=true, onClick}) => {

  const {t} = useTranslation();

  const price : string = `${formatMoney(
    editItem.quantity * (editItem.product==null?(
      SalePath.cumulatePrice(editItem.salePath) + editItem.getForcedPrices()
  ):(
    editItem.getForcedPrices()
  ))
  )} €`

  return (
    <div className={styles.addToCart}>
      <Button color='primary' variant='contained' onClick={()=>{ if (onClick)onClick()}} disabled={!enabled} className={styles.button}>
        <div className={styles.buttonText}>
          <Typography variant="h3" >{editItem.product ? t('button.update-basket') : t('button.add-basket')}</Typography>
          <Typography variant="h3" >{price}</Typography>
        </div>
      </Button>
    </div>
  );
};

export default AddToCart;
