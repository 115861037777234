import { FC, useContext, useEffect, useState } from 'react';
import { KioskContext } from '../../../context/KioskContext';
import { KioskActionsEnum, StepsEnum } from '../../../context/KioskContext/types';
import styles from './styles.module.scss';
import Cart from '../../../models/cart';
import { deleteProductRow, DeliveryMethodEnum, getCart } from '../../../helpers/basketApi';
import { useNavigate } from 'react-router-dom';
import useWindowState from '../../../hooks/useWindowState';
import Header from '../../components/Header';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { addLocalHistory, finalizeCartState, removeCartState, updateCartState } from '../../../helpers/localStorageHelper';
import SalePath from '../../../models/SalePath';
import Product from '../../../models/product';

const Checkout: FC = () => {

  const {state, dispatch} = useContext(KioskContext);
  const navigate = useNavigate();
  const { width: windowWidth } = useWindowState();
  const {t} = useTranslation();
  
  const searchParams:URLSearchParams = new URLSearchParams(window.location.search);
  const cartId: string|null = searchParams.get('ref');  
  
  var status: string|null = searchParams.get('status');  
  if (status)status=status.toLowerCase();

  const [cart, setCart] = useState<Cart|null>(null);
  const [loadingCart, setLoadingCart] = useState<boolean>(true);
  const [processedResponse, setProcessedResponse] = useState<boolean>(false);

  useEffect(()=>{
    const getReferencedCart = async (cartId:string) : Promise<Cart|null> => {
      const cart = await getCart(cartId);
      return cart;
    }
    if (cartId && state.cart && cartId.toLowerCase()===state.cart.id.toLowerCase()){
      setCart(state.cart);
      setLoadingCart(false);
    }
    else {
      if (cartId){
        getReferencedCart(cartId).then(c => {
          setCart(c);
          if (c)
            updateCartState(c);
          else
          removeCartState();
          //console.log('StoreCart', cart);
          dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
          setLoadingCart(false);
        });
      }
      else {
        setLoadingCart(false);
      }
    }
  },[cart, cartId, dispatch, state.cart]);

  useEffect(()=>{
    const handlePaidCart = async (cart: Cart) => {
      if (state.cart){
        setProcessedResponse(true);
        finalizeCartState(cart);
        addLocalHistory(cart);
  
        dispatch({ type: KioskActionsEnum.SET_STEP, stap: StepsEnum.collect });
        dispatch({ type: KioskActionsEnum.OBSERVE_CART, cartId: state.cart.id });
        navigate('/');
      }
    }
  
    const handleFailedCart = async (cart: Cart) => {
      if (state.cart){
        setProcessedResponse(true);
        const curbsideItem: SalePath|undefined = state.salePathData.getCurbsideItem();
        if (curbsideItem && cart.deliveryMethod === DeliveryMethodEnum.Curbside){
          const cartProduct: Product|undefined = cart.products.find(x => x.productCode === curbsideItem.productCode);
          if (cartProduct){
            const updated = await deleteProductRow(cart.id, cartProduct.rowId);
            if (updated==null){
              dispatch({ type: KioskActionsEnum.RESET_CART });
              return;
            }
            dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: updated });
          }
        }
      }
    }
  
    if (!loadingCart && state.cart && processedResponse === false){
      if (status==='ok'){
        handlePaidCart(state.cart);
      } else {
        handleFailedCart(state.cart);
      }
    }
  },[dispatch, loadingCart, navigate, processedResponse, state.cart, state.salePathData, status]);

  const onContinue = () => {
    //console.log('State', 'Fail', 'On continue', state.cart);
    dispatch({ type: KioskActionsEnum.SET_STEP, step: StepsEnum.collect });
    dispatch({ type: KioskActionsEnum.SELECT_ROOT });
    if (state.cart!=null)
      dispatch({ type: KioskActionsEnum.SET_STEP, step: StepsEnum.order });
    navigate('/');
  }

  if (!loadingCart && state.cart==null){
    status = 'Unknown cart';
  }

  const heightHeader:number = 60;
  const heightContainer:number = window.innerHeight - heightHeader;
  const maxWidth:number|null = 600;
  const width:number|null = windowWidth ? windowWidth < maxWidth ? windowWidth : maxWidth : null;

  const isFinalized:boolean = (loadingCart || (status==='ok' && state.cart?.state.toLowerCase()==='finalized'));

  return (
    <div className={styles.root} style={{maxWidth:`${width&&`${width}px`}`}}>
      <div className={styles.header} id='header'>
        <Header disabled={true}/>
      </div>
      <div className={styles.mainContainer} id='container' style={{height:`${heightContainer}px`}}>
      <div className={styles.titleItem}>
        <Typography variant="h2">{loadingCart ? t('caption.payment-processing'): isFinalized ? t('caption.payment-processed') : t('caption.payment-failed')}</Typography>
      </div>
      {!loadingCart && (
        <>
          {isFinalized ? (
            <div className={styles.titleItem}>
              <Typography variant="h3">{t('caption.payment-registered')}</Typography>
            </div>
          ) : (
            <>
              <div className={styles.titleItem}>
                <Typography variant="h3">{t('caption.try-again')}</Typography>
              </div>
              <div className={styles.titleItem}>
                <Button color='primary' variant='contained' onClick={onContinue} className={styles.button}>{t('button.proceed-to-order')}</Button>
              </div>
            </>
          )}
        </>
        )}
      </div>
    </div>      
  );
};

export default Checkout;
