import { FC, useContext } from 'react';
import styles from './styles.module.scss';
import { Button, Typography } from '@mui/material';
import { formatMoney } from '../../../../helpers/formatHelper';
import { useTranslation } from "react-i18next";
import { KioskActionsEnum, StepsEnum } from '../../../../context/KioskContext/types';
import { KioskContext } from '../../../../context/KioskContext';

interface Props {
}

const OpenOrder: FC<Props> = () => {

  const {state, dispatch} = useContext(KioskContext);
  const {t} = useTranslation();

  const price : string|undefined = (state.cart && state.cart.state.toLowerCase()!=='finalized' && state.cart.receiptTotal > 0) ? `${formatMoney(state.cart.receiptTotal)} €`: undefined;

  const proceedWithPayment = () => {
    dispatch({ type: KioskActionsEnum.SET_NEXT_STEP });
  }

  const generateCaption = () : string => {
    switch(state.step){
      case StepsEnum.collect: {
        return t('button.proceed-to-cart');
      }
      case StepsEnum.cart: {
        return t('button.proceed-to-order');
      }
      default: {return '';}
    }
  }

  if (state.step !== StepsEnum.collect && state.step !== StepsEnum.cart)return (<></>);
  if (state.salePath?.productID)return (<></>);
  //console.log('asd', state.salePath);

  return (
    <div className={styles.openOrder}>
      <Button color='primary' variant='contained' onClick={proceedWithPayment} className={styles.button}>
        <div className={styles.buttonText}>
          <Typography variant="h3" >{generateCaption()}</Typography>
          <Typography variant="h3" >{price}</Typography>
        </div>
      </Button>
    </div>
  );
};

export default OpenOrder;
